import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Sidebar = () => {
  const { logout } = useAuth0();
  const [isChatExpanded, setIsChatExpanded] = useState(true);
  const location = useLocation();

  return (
    <div className="w-28 bg-white shadow-md flex flex-col h-full">
      <div className="p-2">
        <h1 className="text-2xl font-bold text-gray-800 pb-2 tracking-tight">
          <span className="bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            Asterio
          </span>
        </h1>
      </div>
      <nav className="mt-6 flex-grow">
        <div className="relative">
          <div className="flex items-center">
            <Link
              to="/"
              className={`flex-grow py-2 px-2 text-gray-700 hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500 ${
                location.pathname === '/' ? 'bg-gray-100 border border-black ring-1 ring-offset-1 ring-blue-500' : ''
              }`}
            >
              Chat
            </Link>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsChatExpanded(!isChatExpanded);
              }}
              className="px-2 py-2 text-gray-500 hover:text-gray-700"
            >
              <svg
                className={`w-4 h-4 transform transition-transform ${isChatExpanded ? 'rotate-180' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
          <div className={`transition-all duration-200 ${isChatExpanded ? 'max-h-60' : 'max-h-0 overflow-hidden'}`}>
            <Link
              to="/get-started"
              className="block py-2 pl-4 pr-2 text-sm text-gray-700 font-medium hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500"
            >
              Get Started
            </Link>
            <Link
              to="/history"
              className="block py-2 pl-4 pr-2 text-sm text-gray-700 font-medium hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500"
            >
              History
            </Link>
            <Link
              to="/documents"
              className="block py-2 pl-4 pr-2 text-sm text-gray-700 font-medium hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500"
            >
              Documents
            </Link>
            <Link
              to="/profile"
              className="block py-2 pl-4 pr-2 text-sm text-gray-700 font-medium hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500"
            >
              Profile
            </Link>
          </div>
        </div>
        <Link
          to="/resources"
          className="block py-2 px-2 text-gray-700 hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500"
        >
          Resources
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
