import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';

const AutocompleteInput = ({ value, onChange, isLoading, onSubmit }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [recentQueries, setRecentQueries] = useState([]);

  // Enhanced suggestions object
  const enhancedSuggestions = {
    general: [
      'Give me an example of',
      'What are the best practices for',
      'Compare and contrast',
      'Show me resources about',
      'Help me understand',
      'What are the steps for',
      'Can you provide guidance on',
      'How can I advocate for my child with special needs',
      'What are common misconceptions about',
      'How to collaborate effectively with',
      'Best practices for inclusive education',
      'How to differentiate instruction for diverse learners',
      'Effective communication strategies with parents and staff',
      'What are evidence-based practices for',
      'How to create an inclusive environment for',
      'Research-backed strategies for',
      'Latest developments in',
      'Success stories about',
      'Common challenges with',
      'Tips and tricks for',
      'Expert recommendations for',
      'Step-by-step guide to',
      'Essential resources for',
      'Key considerations when',
      'How to evaluate effectiveness of',
      'Practical solutions for',
      'Quick guide to',
      'Most effective methods for',
      'How to measure success in',
      'Best tools and resources for',
      'Professional development opportunities in',
    ].sort(),
    iep: [
      'Explain the difference between IEP and 504 plan',
      'Help me with accommodations for students with',
      'How do I write IEP goals for',
      'How often should an IEP be reviewed and updated',
      'How to document IEP progress',
      'How to measure progress on IEP goals',
      'How to modify IEP for',
      'How to prepare for my child\'s first IEP meeting',
      'How to write SMART goals for IEP',
      'IEP meeting tips for parents',
      'Legal requirements for IEP implementation',
      'Strategies for IEP team collaboration',
      'Tips for conducting effective IEP meetings',
      'What are common IEP accommodations for',
      'What are my rights as a parent in the IEP process',
      'What to do if I disagree with the IEP team\'s decision',
    ].sort(),
    lesson: [
      'Lesson plan for teaching',
      'Activity ideas for students with',
      'How to teach',
      'Assessment strategies for',
      'Differentiation techniques for',
      'Project-based learning ideas for',
      'Classroom management tips for special education',
      'Technology integration in lessons for students with disabilities',
      'How to create inclusive lesson plans',
      'Strategies for teaching students with diverse learning needs',
      'Multi-sensory teaching approaches for',
      'How to modify curriculum for students with',
      'Effective grouping strategies for mixed-ability classrooms',
    ],
    assessment: [
      'How to assess student progress for',
      'What assessment tools work best for',
      'Progress monitoring strategies for',
      'Data collection methods for IEP goals',
      'How to document behavioral assessments',
      'Assessment accommodations for students with',
      'Tools for measuring academic progress',
      'How to evaluate social-emotional goals',
      'Alternative assessment methods for students with disabilities',
      'How to interpret standardized test results for students with IEPs',
      'Formative assessment strategies for special education',
      'How to use assessment data to inform instruction',
    ],
    transition: [
      'Transition planning for students with',
      'Career exploration activities for special education',
      'How to develop self-advocacy skills in students',
      'Post-secondary education options for students with disabilities',
      'Vocational training programs for special needs students',
      'Life skills curriculum for transition planning',
      'Community involvement opportunities for students with disabilities',
      'How to create a transition portfolio',
      'Job training resources for students with',
      'Independent living skills assessment for',
      'How to write transition goals for',
      'College preparation strategies for students with disabilities',
      'How to support students in developing executive functioning skills',
      'Tell me about workplace readiness skills for students with disabilities',
      'Tell me about supported employment options',
      'Tell me about transition assessments and their importance',
      'Tell me about developing independent living skills',
      'Tell me about financial literacy for transitioning students',
      'Tell me about social skills training for workplace success',
      'Tell me about transportation training for independence',
      'Tell me about post-secondary education accommodations',
      'Tell me about vocational rehabilitation services',
      'Tell me about transition planning timeline requirements',
      'Tell me about parent involvement in transition planning',
      'Tell me about assistive technology for workplace success',
      'Tell me about job coaching services',
      'Tell me about self-determination skills development',
      'Tell me about community-based instruction',
      'Tell me about transition readiness assessments',
      'Tell me about transition resources for rural areas',
      'Explain the importance of career exploration in transition planning',
      'Explain the importance of self-advocacy skills for students with disabilities',
      'Explain the importance of work-based learning experiences',
      'Explain the importance of age-appropriate transition assessments',
      'Explain the importance of family engagement in transition planning',
      'Explain the importance of interagency collaboration',
      'Explain the importance of student-led IEP meetings',
      'Explain the importance of social skills training for employment success',
      'Explain the importance of early transition planning',
      'Explain the importance of life skills instruction',
      'Explain the importance of community partnerships in transition',
      'Explain the importance of vocational assessments',
      'Explain the importance of person-centered planning',
      'Explain the importance of assistive technology in transition',
      'Explain the importance of financial literacy for independence',
    ],
    behavior: [
      'Behavior intervention strategies for',
      'How to write a behavior support plan',
      'Positive reinforcement techniques for',
      'De-escalation strategies for',
      'Social skills training activities for',
      'How to document behavioral incidents',
      'Classroom management tips for students with',
      'How to conduct a functional behavior assessment',
      'Strategies for managing challenging behaviors in the classroom',
      'How to teach self-regulation skills to students with',
      'Positive behavior support systems for schools',
      'How to create a behavior contract with a student',
    ],
    accommodation: [
      'Classroom accommodations for',
      'Testing modifications for students with',
      'Assistive technology options for',
      'Environmental adaptations for',
      'Learning supports for students with',
      'How to implement accommodations in',
      'Universal Design for Learning strategies',
      'How to determine appropriate accommodations for a student',
      'Differentiating between accommodations and modifications',
      'Technology tools to support students with learning disabilities',
      'How to create an accessible classroom environment',
      'Accommodations for students with sensory processing issues',
    ],
    family: [
      'How can I support my child\'s learning at home',
      'What questions should I ask at an IEP meeting',
      'How to communicate effectively with my child\'s teachers',
      'Resources for parents of children with special needs',
      'How to explain my child\'s disability to siblings',
      'Support groups for parents of children with',
      'How to help my child build social skills',
      'What are my child\'s rights in special education',
      'How to handle bullying of my child with special needs',
      'Tips for managing stress as a parent of a child with disabilities',
    ],
    legal: [
      'What are the key components of IDEA',
      'Explain FAPE (Free Appropriate Public Education)',
      'What is the difference between accommodation and modification',
      'How to file a due process complaint',
      'What is least restrictive environment (LRE)',
      'Rights of students with disabilities in disciplinary actions',
      'How to request an independent educational evaluation',
      'Timeline requirements for IEP implementation',
      'What is prior written notice in special education',
    ],
    disability: [
      'Strategies for teaching students with ASD',
      'How to support social skills in students with ASD',
      'Classroom accommodations for students with ASD',
      'How to help students with ADHD focus',
      'Behavior management techniques for ADHD',
      'Organizational tools for students with ADHD',
      'Reading interventions for students with dyslexia',
      'Assistive technology for dyslexia',
      'Early signs of dyslexia and assessment methods',
    ].sort(),
    professional: [
      'Online courses for special education teachers',
      'Certification requirements for special education',
      'How to stay updated with special education laws',
      'Managing stress as a special education teacher',
      'Work-life balance tips for educators',
      'How to work with paraprofessionals effectively',
      'Strategies for co-teaching in inclusive classrooms',
      'Documentation best practices for special education',
      'Understanding compliance reviews and audits',
      'Maintaining confidentiality in student records',
    ].sort(),
    mental_health: [
      'Identifying signs of anxiety in students',
      'Supporting students with depression',
      'Creating a trauma-informed classroom', 
      'Support networks for parents of children with disabilities',
      'Understanding guardianship and legal considerations',
      'Strategies for managing student stress',
      'Building emotional resilience in students',
      'Mental health resources for educators',
      'Supporting students through grief and loss',
      'Creating safe spaces for emotional expression'
    ].sort(),
    technology: [
      'Using educational apps for special education',
      'Virtual learning strategies for students with disabilities',
      'Engaging students with interactive whiteboards', 
      'Incorporating assistive technology in lessons',
      'Using visual schedules and supports',
      'Text-to-speech tools for accessibility',
      'Speech recognition software in education',
      'Digital organization tools for students',
      'Adaptive technology for physical disabilities',
      'Online collaboration tools for special education',
      'Using AI tools to support student learning',
      'Implementing LLMs in special education',
      'Benefits of LLMs as learning aids',
      'LLMs as learning aids',
      'LLMs in education'
    ].sort(),
    seasonal: [
      'Preparing students for the new school year',
      'Summer activities to prevent learning loss',
      'Transitioning back to school after breaks',
      'Holiday season accommodations and supports',
      'End of year assessment strategies',
      'Planning for extended school breaks',
      'Summer enrichment program ideas',
      'Managing seasonal changes and routines'
    ].sort(),
  };
  
  useEffect(() => {
    // Load recent queries from local storage
    const storedQueries = localStorage.getItem('recentQueries');
    if (storedQueries) {
      setRecentQueries(JSON.parse(storedQueries));
    }
  }, []);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return recentQueries.slice(0, 5); // Show recent queries when input is empty
    }

    let matchedSuggestions = [];

    // Check for matches in all categories
    Object.values(enhancedSuggestions).forEach(category => {
      matchedSuggestions = matchedSuggestions.concat(
        category.filter(suggestion =>
          suggestion.toLowerCase().includes(inputValue)
        )
      );
    });

    // Add dynamic suggestions based on input
    if (inputValue.startsWith('how')) {
      matchedSuggestions.push(`How to ${inputValue.slice(3)}...`);
    } else if (inputValue.startsWith('what')) {
      matchedSuggestions.push(`What is ${inputValue.slice(4)}...`);
    }

    // Add a custom suggestion
    matchedSuggestions.push(`Tell me more about "${inputValue}"`);

    return matchedSuggestions.slice(0, 10); // Limit to 10 suggestions
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => (
    <div className="p-1 hover:bg-gray-100 text-sm">
      {suggestion}
    </div>
  );

  // Define inputProps before using it
  const inputProps = {
    placeholder: 'Type your question...',
    value: value,
    onChange: onChange,
    className: 'flex-1 w-full px-3 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden',
    disabled: isLoading,
    style: { 
      width: '100%',
      minHeight: '32px', 
      maxHeight: '200px',
      lineHeight: '24px'
    }
  };

  const theme = {
    container: 'relative flex-1 w-full',
    suggestionsContainer: 'absolute w-full bg-white border rounded shadow-lg z-10 bottom-full mb-1',
    suggestionsList: 'list-none m-0 p-0 text-sm leading-tight',
  };

  // Add renderSuggestionsContainer to handle container positioning
  const renderSuggestionsContainer = ({ containerProps, children }) => {
    const { ref, ...restContainerProps } = containerProps;
    return (
      <div
        {...restContainerProps}
        ref={ref}
        className={`${theme.suggestionsContainer} max-h-60 overflow-y-auto`}
      >
        {children}
      </div>
    );
  };

  // Add this function to save a new query
  const saveQuery = (query) => {
    // Only save if query isn't in predefined suggestions
    const isCustomQuery = !Object.values(enhancedSuggestions).some(
      category => category.includes(query)
    );

    if (isCustomQuery) {
      const updatedQueries = [query, ...recentQueries.filter(q => q !== query)].slice(0, 5);
      setRecentQueries(updatedQueries);
      localStorage.setItem('recentQueries', JSON.stringify(updatedQueries));
    }
  };

  // You'd need to call this when a query is submitted
  // For example, you might add an onSubmit prop:
  const handleSubmit = (query) => {
    saveQuery(query);
    onSubmit(query); // Pass to parent component
  };

  // Add this to handle when a suggestion is selected
  const onSuggestionSelected = (event, { suggestion }) => {
    if (onSubmit) {
      onSubmit(suggestion);
    }
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={inputProps}
      theme={theme}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default AutocompleteInput;