import { useAuth0 } from '@auth0/auth0-react';

export function useSubscription() {
  const { user, isAuthenticated } = useAuth0();
  
  console.log('User object:', user);
  
  // Try multiple common paths for roles
  const isPaid = 
    user?.['https://login.asterio.io/roles']?.includes('Paid') || // Custom namespace
    user?.['roles']?.includes('Paid') || // Direct roles property
    user?.['https://login.asterio.io/user_metadata']?.roles?.includes('Paid'); // User metadata
  
  console.log('Is user paid:', isPaid);

  return {
    isLoading: false,
    hasAccess: isPaid
  };
}

export function SubscriptionGate({ children }) {
  const { isAuthenticated } = useAuth0();
  const { hasAccess } = useSubscription();
  const STRIPE_PAYMENT_LINK = process.env.REACT_APP_STRIPE_PAYMENT_LINK;

  if (!isAuthenticated || !hasAccess) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-center text-blue-900 mb-4">Get Started with Asterio</h2>
            <p className="text-center text-gray-700 mb-4">
              Enjoy a 3-day free trial, then just $15.00/month.
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-700">
              <li>Access all standard features.</li>
              <li>Cancel anytime</li>
              <li>Secure payment processing via Stripe</li>
            </ul>
            <button
              onClick={() => window.location.href = STRIPE_PAYMENT_LINK}
              className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Start Free Trial
            </button>
          </div>
        </div>
      </div>
    );
  }

  return children;
} 