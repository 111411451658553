import React from 'react';
import { Link } from 'react-router-dom';

const GetStarted = () => {
  const exampleQuestions = [
      {
          question: "What are the key components of an effective IEP?",
          complexity: "Starter"
      },
      {
          question: "What strategies can I use to differentiate instruction for students with varying learning needs?",
          complexity: "Starter"
      },
      {
        question: "Can you help me design a support plan within the multi-tiered  MTSS framework for a middle school student with ADHD and anxiety who is struggling with executive functioning and social interactions? Include specific interventions for each tier and how to monitor progress.",
        complexity: "Intermediate"
    },
    {
        question: "We have a high school student with ADHD who excels in math but struggles with organization and time management in other subjects. How can we support their strengths while addressing their executive functioning challenges? What strategies would you recommend to help improve their performance across all classes?",
        complexity: "Intermediate"
    },
    {
        question: "I need to develop a 9 or 15 week, 2x/week protocol for a photovoice project. I will have 3 students participating, and all understand the concept of self-determination if only generally, and all are comfortable with a camera. They are all creative and some have not yet found their voice, so I hope this brings something out of them. We meet formally 2x/week for 1-1.5 hours. Students will have access to a camera outside of this time.",
        complexity: "Intermediate"
    },
      {
          question: "We're implementing a peer-mediated intervention program for students with autism in our high school. Can you provide a comprehensive plan that includes: training protocols for peer mentors, strategies for generalizing social skills across settings, data collection methods to measure effectiveness, and ways to align this program with secondary transition planning for all of our students on IEPs?",
          complexity: "Advanced"
      },
      {
        question: "I'm preparing for an IEP meeting for a 10th-grade student with dyscalculia and anxiety. They're performing three grade levels below in math but above grade level in reading and writing. The student aspires to pursue a career in journalism but is at risk of not meeting graduation requirements due to their math performance. How can I develop a comprehensive IEP that addresses their specific math deficits using evidence-based interventions for dyscalculia, leverages their strengths in reading and writing across the curriculum, and incorporates strategies to manage their anxiety, particularly in math-related situations? The plan should align with their post-secondary goals in journalism, include appropriate accommodations for both classroom instruction and state standardized tests, and outline a plan for credit recovery or alternative paths to meet graduation requirements. Additionally, how can we structure the IEP meeting to effectively collaborate with the student, parents, general education teachers, and guidance counselor to ensure buy-in and consistent implementation of the plan?",
        complexity: "Advanced"
    }
  ];

    return (
        <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md border border-black">
          <h1 className="text-3xl font-bold mb-6">How I Can Help You</h1>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Welcome to Your Educational Support Assistant
            </h2>
            <p className="mb-4">
              Asterio is here to provide informed, empathetic, and practical guidance to
              educators, families, and support staff in the realm of special
              education. My goal is to help you navigate the complexities of
              Individualized Education Programs (IEPs), transition planning, and
              inclusive education practices.
            </p>
            <p className="mb-4">
              Whether you're an experienced educator, a concerned parent, or a
              support staff member, Asterio is here to offer support and information
              tailored to your specific needs and questions.
            </p>
          </section>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Navigating the App
            </h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                <strong>Chat:</strong> Your main interface for interacting with Asterio.
              </li>
              <li>
                <strong>History:</strong> Review and export your past conversations for future reference. 
              </li>
              <li>
                  <strong>Documents:</strong> Upload files. You can also ask Asterio to analyze them to provide context for your questions by saying things like "How does this document relate to [topic]?"
                </li>
              <li>
                <strong>Profile:</strong> Customize your user information to enhance
                your interactions with Asterio and help tailor responses and related questions to your specific needs.
              </li>
              <li>
                <strong>Resources:</strong> A list of educational resources to support your learning and teaching needs.
              </li>
              <li>
                <strong>Quick Tips:</strong> Discover helpful tips to give you new ideas and strategies.
              </li>
            </ul>
          </section>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              What I Can Assist You With
            </h2>
            <p className="mb-4">
              Asterio can provide information, guidance, and resources related to:
            </p>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                <strong>Special Education Principles and Practices:</strong>{' '}
                Understanding core concepts, current best practices, and strategies
                for creating inclusive learning environments.
              </li>
              <li>
                <strong>IEP Development and Implementation:</strong> Navigating the
                process of creating, reviewing, and implementing effective IEPs that
                truly serve the student's needs.
              </li>
              <li>
                <strong>Transition Planning:</strong> Developing comprehensive
                transition plans for students moving to post-secondary education or
                employment.
              </li>
              <li>
                <strong>Collaborative Approaches:</strong> Fostering effective
                collaboration among educators, families, and support staff.
              </li>
              <li>
                <strong>Evidence-Based Teaching Strategies:</strong> Exploring
                research-backed methods for supporting diverse learners.
              </li>
              <li>
                <strong>Progress Monitoring and Goal Setting:</strong>{' '}
                Understanding effective ways to track student progress and set
                achievable goals.
              </li>
              <li>
                <strong>Educational Law and Policy (General Information):</strong>{' '}
                Gaining a general understanding of special education laws and
                policies (always consult legal professionals for specific advice).
              </li>
              <li>
                <strong>Template Generation:</strong> Asterio can help you create various educational templates:
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>
                    <strong>IEP Templates:</strong> Create Individualized Education Program templates tailored to student needs
                  </li>
                  <li>
                    <strong>UDL Templates:</strong> Generate Universal Design for Learning frameworks
                  </li>
                  <li>
                    <strong>Lesson Plan Templates:</strong> Design structured lesson plans incorporating accommodations and modifications
                  </li>
                  <li>
                    <strong>Data Collection Templates:</strong> Create formats for tracking student progress and interventions
                  </li>
                </ul>
                <p className="mt-2">
                  Access templates by clicking the "Templates" button in the chat interface and selecting the type of template you need. It will be generated based on the context of your current conversation.
                </p>
              </li>
            </ul>
          </section>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Example Questions to Get You Started
            </h2>
            <p className="mb-4">
              Here are some sample questions to give you an idea of what you can ask:
            </p>
            <ul className="space-y-4">
              {exampleQuestions.map((item, index) => (
                <li key={index} className="border-l-4 border-blue-500 pl-4">
                  <p className="font-semibold">{item.complexity}:</p>
                  <p>{item.question}</p>
                </li>
              ))}
            </ul>
            <p className="mt-4">
              Feel free to ask questions similar to these, or any others related to special education, IEPs, or transition planning!
            </p>
          </section>
    
          <section className="mb-8">
  <h2 className="text-2xl font-semibold mb-4">
    How to Get the Most Out of Our Conversation
  </h2>
  <p className="mb-4">
    Asterio is here to provide personalized guidance. To help Asterio understand your needs and provide the most relevant information, try these tips:
  </p>
  <ul className="list-disc pl-6 space-y-2">
    <li>
      <strong>Be Clear and Specific:</strong> Just like talking to a person, tell Asterio exactly what you need help with. The more details you provide, the better Asterio can assist you.
    </li>
    <li>
      <strong>Paint a Picture with Context:</strong> Give Asterio background information about the student or situation you're dealing with. For example, tell Asterio the student's grade level, any specific challenges they face, or what you've already tried.
    </li>
    <li>
      <strong>Share Your Resources:</strong> Let Asterio know what resources or support systems are currently available to you. This helps Asterio tailor suggestions to your specific context. If you find yourself telling Asterio things multiple times add the information to your Profile.
    </li>
    <li>
      <strong>Don't Be Afraid to Ask!</strong> Asterio learns from every interaction. If you need more explanation, have follow-up questions, or want to explore a topic further, just ask! 
    </li>
  </ul>
</section>
<section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Support & FAQ
            </h2>
            <div className="space-y-4">
              <div className="border-l-4 border-gray-300 pl-4">
                <p className="font-semibold">How do I save my conversations?</p>
                <p>All conversations are automatically saved in your History tab. You can access them anytime and export them as needed.</p>
              </div>
              <div className="border-l-4 border-gray-300 pl-4">
                <p className="font-semibold">Can I customize my experience?</p>
                <p>Yes! Set up your profile with student details, preferences, and common scenarios to get more personalized responses.</p>
              </div>
              <div className="border-l-4 border-gray-300 pl-4">
                <p className="font-semibold">What if I need to upload documents?</p>
                <p>Use the Documents tab to upload and manage your files. Asterio can reference these documents during your conversations to provide more contextual support.</p>
              </div>
              <div className="border-l-4 border-gray-300 pl-4">
                <p className="font-semibold">Is my information private?</p>
                <p>Yes, your conversations and uploaded documents are private and secure. We never share your information with third parties.</p>
              </div>
              <div className="border-l-4 border-gray-300 pl-4">
                <p className="font-semibold">What if I need technical support?</p>
                <p>For technical issues or questions, please contact our support team at support@asterio.io or use the feedback button in your Profile.</p>
              </div>
              <div className="border-l-4 border-gray-300 pl-4">
                <p className="font-semibold">How do I cancel my subscription?</p>
                <p>You can manage or cancel your subscription through our <a href="https://billing.stripe.com/p/login/6oE04793cdKG3cscMM" className="text-blue-600 hover:text-blue-800 underline">customer portal</a>. Simply log in with your email address to access your subscription settings.</p>
              </div>
            </div>
          </section>
    
          <p className="mb-6">
            Asterio is here to support you in creating effective, inclusive, and
            personalized educational experiences for students with diverse needs.
            Every question is valuable, and Asterio is here to help you explore solutions
            and strategies. Are you ready to start your conversation with Asterio?
          </p>
    
          <Link
            to="/"
            className="btn-secondary"
          >
            Start a Conversation
          </Link>
        </div>
      );
    };

export default GetStarted;
