// Helper function for formatting content
const convertToQuillFormat = (content) => {
  const lines = content.split('\n');
  let formattedContent = '';
  let inTable = false;
  
  lines.forEach((line, index) => {
    // Handle table rows
    if (line.includes('|')) {
      if (!inTable) {
        // Start new table
        inTable = true;
        formattedContent += '<table><tbody>';
      }
      
      // Convert markdown table row to HTML
      const cells = line.split('|').filter(cell => cell.trim());
      const isHeader = line.includes('|-');
      
      if (!isHeader) {
        formattedContent += '<tr>';
        cells.forEach(cell => {
          formattedContent += `<td>${cell.trim()}</td>`;
        });
        formattedContent += '</tr>';
      }
    } else {
      // Handle end of table
      if (inTable) {
        inTable = false;
        formattedContent += '</tbody></table><p></p>';
      }
      
      // Handle existing formatting
      if (line.startsWith('# ')) {
        formattedContent += `<h1 style="text-align: center;"><strong>${line.replace('# ', '')}</strong></h1>\n\n`;
      }
      else if (line.startsWith('## ')) {
        formattedContent += `<h2><strong>${line.replace('## ', '')}</strong></h2>\n\n`;
      }
      else if (line.startsWith('### ')) {
        formattedContent += `<h3><strong>${line.replace('### ', '')}</strong></h3>\n\n`;
      }
      else if (line.startsWith('• ') || line.startsWith('- ')) {
        if (line.includes(':**')) {
          const [label, value] = line.replace(/[•-]\s*\*\*/, '').split(':**');
          formattedContent += `<p>• <strong>${label}:</strong>${value || ''}</p>\n`;
        } else if (line.includes(':')) {
          const [label, value] = line.replace(/[•-]\s*/, '').split(':');
          formattedContent += `<p>• <strong>${label}:</strong>${value || ''}</p>\n`;
        } else {
          formattedContent += `<p>${line}</p>\n`;
        }
      }
      else {
        formattedContent += `<p>${line}</p>\n`;
      }
    }
  });

  // Close table if content ends with table
  if (inTable) {
    formattedContent += '</tbody></table>';
  }

  return formattedContent
    .replace(/\n{3,}/g, '\n\n')
    .replace(/\*\*/g, '')
    .trim();
};

// Template generator functions
export const generateIEP = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  const context = fromTemplate && chatMessages.length === 0
    ? "Generate a new standard IEP template"
    : chatMessages.slice(-5).map(msg => msg.content).join('\n');

  const response = await fetchWithToken(`${API_BASE_URL}/api/generate-iep-richtext`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      context,
      fromTemplate,
    }),
  });

  const data = await response.json();
  return convertToQuillFormat(data.iepTemplate);
};

export const generateUDL = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  const context = fromTemplate && chatMessages.length === 0
    ? "Generate a new standard UDL template"
    : chatMessages.slice(-5).map(msg => msg.content).join('\n');

  const response = await fetchWithToken(`${API_BASE_URL}/api/generate-udl`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      context,
      fromTemplate,
    }),
  });

  const data = await response.json();
  return convertToQuillFormat(data.udlTemplate);
};

export const generateLessonPlan = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  try {
    const response = await fetchWithToken(`${API_BASE_URL}/api/generate-lesson-plan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: chatMessages,
        fromTemplate,
      }),
    });

    const data = await response.json();
    
    if (!data || !data.content) {
      throw new Error('Invalid response format from server');
    }

    return convertToQuillFormat(data.content);
  } catch (error) {
    console.error('Error generating lesson plan:', error);
    throw error;
  }
}; 

export const generateDataCollection = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  try {
    const context = fromTemplate && chatMessages.length === 0
      ? "Generate a new standard data collection template"
      : chatMessages.slice(-5).map(msg => msg.content).join('\n');

    const response = await fetchWithToken(`${API_BASE_URL}/api/generate-data-collection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        context,
      }),
    });

    const data = await response.json();
    
    if (!data || !data.dataCollectionTemplate) {
      throw new Error('Invalid response format from server');
    }

    return convertToQuillFormat(data.dataCollectionTemplate);
  } catch (error) {
    console.error('Error generating data collection:', error);
    throw error;
  }
};

// Add this new function
export const copyRichTextToClipboard = async (content, onSuccess, onError) => {
  let tempDiv = null;
  try {
    // Try modern clipboard API first
    if (navigator.clipboard && window.ClipboardItem) {
      const blob = new Blob([content], { type: 'text/html' });
      const data = [new ClipboardItem({ 'text/html': blob })];
      await navigator.clipboard.write(data);
      onSuccess();
      return;
    }

    // Fallback to older method
    tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    tempDiv.style.position = 'fixed';
    tempDiv.style.left = '-9999px';
    // Preserve whitespace and formatting
    tempDiv.style.whiteSpace = 'pre-wrap';
    document.body.appendChild(tempDiv);

    const range = document.createRange();
    range.selectNode(tempDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    const successful = document.execCommand('copy');
    if (!successful) throw new Error('Copy command failed');
    onSuccess();
  } catch (err) {
    console.error('Failed to copy text:', err);
    onError(err);
  } finally {
    // Clean up
    if (tempDiv && document.body.contains(tempDiv)) {
      document.body.removeChild(tempDiv);
    }
    window.getSelection().removeAllRanges();
  }
};