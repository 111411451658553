import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useAuth0 } from "@auth0/auth0-react";
import { useChat } from "../ChatContext";
import axios from "axios";
import { tips } from './tips'; // Import tips from the external file
import { useNavigate } from 'react-router-dom';
import '../styles/typing-indicator.css';
import '../styles/modal.css';
import usePageVisibility from '../hooks/usePageVisibility';
import { useIdleTimer } from 'react-idle-timer';
import ReactQuill, { Quill } from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import 'quill-table';
import remarkGfm from 'remark-gfm';
// import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
// import { saveAs } from 'file-saver';
import Modal from 'react-modal'; // You'll need to install this package
import { Virtuoso } from 'react-virtuoso'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { generateIEP, generateUDL, generateLessonPlan, generateDataCollection, copyRichTextToClipboard } from './templateHandlers';
import AutocompleteInput from './AutocompleteInput';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';

// Simplify formats to just what we need
const formats = [
  'header',
  'bold',
  'list',
  'align',
  'table',
  'td',
  'tr',
  'th',
  'thead',
  'tbody'  // Add alignment support
];

// Simplify toolbar to essential options
const modules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, false] }],  // h1, h2, h3, normal text
      ['bold'],
      [{'list': 'bullet'}],
      [{ 'align': [] }],  // Add alignment options
      ['clean'],
      [{ 'table': true }],  // Add table button to toolbar
    ],
  },
  clipboard: {
    matchVisual: false
  },
  table: true,  // Enable table module
};


const ChatInterface = () => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading: authLoading, logout } = useAuth0();
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { chatMessages, setChatMessages, suggestedQuestions, setSuggestedQuestions } = useChat();
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showTip, setShowTip] = useState(false);
  const [currentTip, setCurrentTip] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const quickTipButtonRef = useRef(null);
  const inputRef = useRef(null);
  const formRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [showGenerateIEP, setShowGenerateIEP] = useState(false);
  const [showIEPEditor, setShowIEPEditor] = useState(false);
  const [iepContent, setIepContent] = useState('');
  const [isIEPModalOpen, setIsIEPModalOpen] = useState(false);
  const [isGeneratingIEP, setIsGeneratingIEP] = useState(false);
  const [userAboutInfo, setUserAboutInfo] = useState('');
  const [accessToken, setAccessToken] = useState(null);

  const [storedSelection, setStoredSelection] = useState(null);
  const [showAIPrompt, setShowAIPrompt] = useState(false);
  const [aiPrompt, setAIPrompt] = useState('');
  const [promptPosition, setPromptPosition] = useState({ top: 0, left: 0 });
  const [isFocused, setIsFocused] = useState(false);
  const [activeButton, setActiveButton] = useState(null);

  const quillRef = useRef(null);     // Add this
  const popupRef = useRef(null); 

  // Replace single quillRef with separate refs for each editor
  const iepQuillRef = useRef(null);
  const udlQuillRef = useRef(null);
  const lessonPlanQuillRef = useRef(null);
  const dataCollectionQuillRef = useRef(null);

  // Add function to get current active editor
  const getCurrentQuillRef = () => {
    console.log('Getting current Quill ref');
    console.log('Modal states:', {
      IEP: isIEPModalOpen,
      UDL: isUDLModalOpen,
      LessonPlan: isLessonPlanModalOpen,
      DataCollection: isDataCollectionModalOpen
    });
    
    let activeRef = null;
    if (isIEPModalOpen && iepQuillRef?.current) {
      console.log('IEP editor active');
      activeRef = iepQuillRef;
    } else if (isUDLModalOpen && udlQuillRef?.current) {
      console.log('UDL editor active');
      activeRef = udlQuillRef;
    } else if (isLessonPlanModalOpen && lessonPlanQuillRef?.current) {
      console.log('Lesson Plan editor active');
      activeRef = lessonPlanQuillRef;
    } else if (isDataCollectionModalOpen && dataCollectionQuillRef?.current) {
      console.log('Data Collection editor active');
      activeRef = dataCollectionQuillRef;
    }
    
    if (!activeRef) {
      console.log('No active editor found');
    } else {
      console.log('Active editor instance:', activeRef.current);
    }
    
    return activeRef;
  };

  const handleOnIdle = () => {
    console.log('User is idle, logging out');
    logout({ returnTo: window.location.origin });
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60, // 1 hour
    onIdle: handleOnIdle,
    debounce: 500
  });

  const isTokenValid = (token) => {
    if (!token) return false;
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    return Date.now() < exp * 1000;
  };

  const refreshToken = useCallback(async () => {
    try {
      const newToken = await getAccessTokenSilently({ ignoreCache: true });
      setAccessToken(newToken);
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout({ returnTo: window.location.origin });
    }
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      if (accessToken && !isTokenValid(accessToken)) {
        refreshToken();
      }
    }, 1000 * 60 * 5); // Check every 5 minutes

    return () => clearInterval(tokenRefreshInterval);
  }, [accessToken, refreshToken]);

  const fetchWithToken = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email",
      });

      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.log("Unauthorized, attempting to refresh token...");
        await refreshToken();
        return fetchWithToken(url, options);
      }

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      return response;
    } catch (error) {
      console.error("Error in fetchWithToken:", error);
      throw error;
    }
  }, [getAccessTokenSilently, refreshToken]);

  const clearChat = () => {
    setChatMessages([]); // This clears the chat messages
    // Optionally, you might want to clear the selected document as well
    setSelectedDocument(null);
  };

  // const generateRelatedQuestions = async (response, userAboutInfo) => {
  //   try {
  //     const result = await fetchWithToken(`${API_BASE_URL}/api/generate-questions`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         context: response,
  //         userAboutInfo: userAboutInfo,
  //       }),
  //     });

  //     const data = await result.json();
  //     setSuggestedQuestions(data.questions.map(q => q.trim()));
  //   } catch (error) {
  //     console.error("Error generating related questions:", error);
  //     setSuggestedQuestions([]);
  //   }
  // };
  
  const handleSuggestedQuestionClick = useCallback((question) => {
    if (inputRef.current) {
      inputRef.current.value = question;
    }
    setInput(question);
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }, 0);
  }, []);

  const saveMessageToHistory = async (message, sender) => {
    try {
      await fetchWithToken(`${API_BASE_URL}/api/chat-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, sender }),
      });
    } catch (error) {
      console.error("Error saving message to history:", error);
    }
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetchWithToken(
          `${API_BASE_URL}/api/documents`,
        );
        const data = await response.json();
        setDocuments(data);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [fetchWithToken]);

  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  const handleNextTip = (e) => {
    e.stopPropagation();
    setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
    setCurrentTip(tips[(currentTipIndex + 1) % tips.length]);
  };

  const handlePreviousTip = (e) => {
    e.stopPropagation();
    setCurrentTipIndex((prevIndex) => (prevIndex - 1 + tips.length) % tips.length);
    setCurrentTip(tips[(currentTipIndex - 1 + tips.length) % tips.length]);
  };

  const showRandomTip = () => {
    if (tips.length > 0) {
      const randomIndex = Math.floor(Math.random() * tips.length);
      setCurrentTipIndex(randomIndex);
      setCurrentTip(tips[randomIndex]);
      
      if (quickTipButtonRef.current) {
        const rect = quickTipButtonRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        
        // Calculate popup width and position
        const popupWidth = 256; // w-64 = 16rem = 256px
        
        // Calculate initial position (10px to the right of the button)
        let leftPosition = rect.right + window.scrollX + 10;
        
        // Check if popup would go off screen and adjust if necessary
        if (leftPosition + popupWidth > windowWidth) {
          // Position to the left of the button instead
          leftPosition = rect.left - popupWidth - 10;
          
          // If still off screen (on the left), align with left edge of window
          if (leftPosition < 0) {
            leftPosition = 10; // 10px margin from left edge
          }
        }
  
        const newPosition = {
          top: rect.bottom + window.scrollY - 100,
          left: leftPosition,
        };
    
        setPopupPosition(newPosition);
      }
      
      setShowTip(true);
    }
  };

  const handleTellMeMore = () => {
    // Define the message you want to send when "Tell me more" is clicked.
    // You can customize this message as needed.
    const message = `Tell me more about: ${currentTip}`;

    setInput(message);       // Set the input state to the predefined message
    setShowTip(false);      // Close the tip popup

    // Use a timeout to ensure that `setInput` has updated the state before submitting
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    }, 0);
  };



  const handleDocumentChange = (newDocumentId) => {
    setSelectedDocument(newDocumentId);
    if (newDocumentId) {
      const newDoc = documents.find(
        (doc) => doc.id === parseInt(newDocumentId),
      );
      if (newDoc) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "user",
            content: `I've selected the document "${newDoc.filename}". Please consider this document's content in your future responses.`,
            hidden: true,
          },
          {
            role: "assistant",
            content: `I see you have selected "${newDoc.filename}". I will take this into account when replying.`,
          },
        ]);
      }
    } else {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "user",
          content: `I've deselected all documents. Please provide general responses without referring to any specific document.`,
          hidden: true,
        },
        {
          role: "assistant",
          content: `Document has been deselected. I will no longer consider it in my reply.`,
        },
      ]);
    }
  };

  const checkForIEPCreationIntent = useCallback((message) => {
    const iepCreationPhrases = [
      'create an iep',
      'generate an iep',
      'make an iep',
      'help with iep',
      'start an iep',
      'begin an iep',
      'draft an iep',
      'develop an iep',
      'write an iep',
      'prepare an iep'
    ];
    const matchedPhrase = iepCreationPhrases.find(phrase => message.toLowerCase().includes(phrase));
    if (matchedPhrase) {
      console.log(`IEP creation intent detected. Matched phrase: "${matchedPhrase}"`);
      return true;
    }
    return false;
  }, []);

  const [streamingMessage, setStreamingMessage] = useState('');
  const streamingRef = useRef('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    // Reset textarea height to default
    if (inputRef.current) {
      inputRef.current.style.height = '32px';
    }

    setSuggestedQuestions([]);
    const userMessage = { role: "user", content: input };
    setChatMessages(prevMessages => [...prevMessages, userMessage]);
    setInput("");
    setIsLoading(true);
    setIsTyping(true);

    try {
      await saveMessageToHistory(input, "user");
      const conversationHistory = chatMessages.map(msg => ({
        role: msg.role === "assistant" ? "assistant" : "user",
        content: msg.content,
      }));

      conversationHistory.push({ role: "user", content: input });
      
      const response = await fetchWithToken(`${API_BASE_URL}/api/chat`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          messages: conversationHistory,
          documentId: selectedDocument,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      try {
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(6));
                
                if (data.done) {
                  if (data.relatedQuestions) {
                    setSuggestedQuestions(data.relatedQuestions);
                  }
                  break;
                }
                if (data.content) {
                  accumulatedResponse += data.content;
                  setChatMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    const lastMessage = newMessages[newMessages.length - 1];
                    if (lastMessage?.role === 'assistant') {
                      lastMessage.content = accumulatedResponse;
                      return [...newMessages];
                    } else {
                      return [...newMessages, { role: 'assistant', content: accumulatedResponse }];
                    }
                  });
                }
              } catch (error) {
                console.error("Error parsing SSE data:", error);
                continue;
              }
            }
          }
        }
      } catch (streamError) {
        console.error("Streaming error:", streamError);
      } finally {
        if (accumulatedResponse) {
          try {
            await Promise.all([
              saveMessageToHistory(accumulatedResponse, "assistant")
            ]);

            const userIntentDetected = checkForIEPCreationIntent(input);
            const assistantIntentDetected = checkForIEPCreationIntent(accumulatedResponse);
            setShowGenerateIEP(userIntentDetected || assistantIntentDetected);
          } catch (error) {
            console.error("Error in post-streaming tasks:", error);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = error.message.includes('400') 
        ? "The request was interrupted. The message may be incomplete."
        : "An error occurred while processing your request.";
      
      setChatMessages(prevMessages => [
        ...prevMessages,
        { role: "error", content: errorMessage }
      ]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const copyToClipboard = (messageId, event) => {
    const iconRect = event.currentTarget.getBoundingClientRect();
  
    // Find the message element in the DOM
    const messageElement = document.getElementById(`message-${messageId}`);
    if (!messageElement) {
      console.error('Message element not found');
      return;
    }
    
    const contentElement = messageElement.querySelector('.message-content');
    if (!contentElement) {
      console.error('Content element not found');
      return;
    }
  
    // Create a range and select the message content
    const range = document.createRange();
    range.selectNodeContents(contentElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  
    try {
      // Execute copy command
      document.execCommand('copy');
  
      // Create and show popup
      const popup = document.createElement('div');
      popup.className = 'bg-white p-2 rounded-lg shadow-lg border border-gray-200 text-sm';
      popup.style.position = 'fixed';
      popup.style.top = `${iconRect.top - 40}px`;
      popup.style.left = `${iconRect.left}px`;
      popup.style.transform = 'translateY(-100%)';
      popup.style.zIndex = '9999';
      popup.textContent = 'Copied to clipboard!';
  
      document.body.appendChild(popup);
  
      // Remove popup after delay
      setTimeout(() => {
        if (popup && document.body.contains(popup)) {
          document.body.removeChild(popup);
        }
      }, 1500);
    } catch (err) {
      console.error('Failed to copy text:', err);
      // Show error popup
      const errorPopup = document.createElement('div');
      errorPopup.className = 'bg-white p-2 rounded-lg shadow-lg border border-red-200 text-sm text-red-500';
      errorPopup.style.position = 'fixed';
      errorPopup.style.top = `${iconRect.top - 40}px`;
      errorPopup.style.left = `${iconRect.left}px`;
      errorPopup.style.transform = 'translateY(-100%)';
      errorPopup.style.zIndex = '9999';
      errorPopup.textContent = 'Failed to copy text';
  
      document.body.appendChild(errorPopup);
  
      setTimeout(() => {
        if (errorPopup && document.body.contains(errorPopup)) {
          document.body.removeChild(errorPopup);
        }
      }, 1500);
    } finally {
      // Clean up
      selection.removeAllRanges();
    }
  };

  const SuggestedQuestions = ({ questions, onQuestionClick }) => (
    <div className="mt-1 space-y-1">
      {questions.map((question, index) => (
        <button
          key={index}
          onClick={() => onQuestionClick(question)}
          className="w-full text-left px-3 py-0.5 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200 text-sm underline text-blue-600"
          title={question} // Full question in tooltip
        >
          <span className="block">
            {question}
          </span>
        </button>
      ))}
    </div>
  );

  // Add a state to track initial load
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Update your fetchInitialSummary effect to set isInitialLoad to false after loading
  useEffect(() => {
    const fetchInitialSummary = async () => {
      if (isAuthenticated && chatMessages.length === 0) {
        try {
          const response = await fetchWithToken(`${API_BASE_URL}/api/chat`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              messages: [],
              documentId: selectedDocument,
            }),
          });

          const data = await response.json();
          if (data.content && Array.isArray(data.content) && data.content.length > 0) {
            setChatMessages([{ role: "assistant", content: data.content[0].text }]);
          }
        } catch (error) {
          console.error("Error fetching initial summary:", error);
        } finally {
          setIsInitialLoad(false);
        }
      }
    };

    if (!authLoading) {
      fetchInitialSummary();
    }
  }, [isAuthenticated, authLoading, chatMessages.length, fetchWithToken, selectedDocument]);

  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const isPageVisible = usePageVisibility();
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current && !userHasScrolled) {
      const { scrollHeight, clientHeight, scrollTop } = chatContainerRef.current;
      const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 100; // 100px threshold

      if (isScrolledToBottom) {
        chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }
  }, [userHasScrolled]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollHeight, clientHeight, scrollTop } = chatContainerRef.current;
        const isScrolledToBottom = scrollHeight - clientHeight <= scrollTop + 100; // 100px threshold
        setUserHasScrolled(!isScrolledToBottom);
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      scrollToBottom();
    }
  }, [isPageVisible, scrollToBottom]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, isTyping, scrollToBottom]);

  const getTokenWithFallback = async () => {
    const authParams = {
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "openid profile email",
    };

    try {
      // Try to get a token from cache first
      return await getAccessTokenSilently({ 
        ...authParams,
        cacheMode: 'cache-first' 
      });
    } catch (error) {
      console.log('Cache miss or token expired, fetching new token');
      // If cache fails, force a new token fetch
      return await getAccessTokenSilently({ 
        ...authParams,
        cacheMode: 'no-cache' 
      });
    }
  };

  const wakeUpCheck = async () => {
    try {
      // Make a lightweight API call to check auth
      await fetchWithToken(`${API_BASE_URL}/api/ping`);
    } catch (error) {
      if (error.message.includes('jwt expired') || error.message.includes('Unauthorized')) {
        console.log('Token expired or unauthorized, refreshing...');
        await getAccessTokenSilently({ 
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email",
          cacheMode: 'no-cache' 
        });
        // Optionally, you might want to reload certain data here
      }
    }
  };

  const handleGenerateIEP = async (fromTemplate = false) => {
    setIsGeneratingIEP(true);
    try {
      const content = await generateIEP(fetchWithToken, API_BASE_URL, chatMessages, fromTemplate);
      setIepContent(content);
      setIsIEPModalOpen(true);
    } catch (error) {
      console.error("Error generating IEP template:", error);
    } finally {
      setIsGeneratingIEP(false);
    }
  };

  // Add these new state variables near your other state declarations
  const [isUDLModalOpen, setIsUDLModalOpen] = useState(false);
  const [isGeneratingUDL, setIsGeneratingUDL] = useState(false);
  const [udlContent, setUdlContent] = useState('');
  const [isLessonPlanModalOpen, setIsLessonPlanModalOpen] = useState(false);
  const [isGeneratingLessonPlan, setIsGeneratingLessonPlan] = useState(false); // Add this line
  const [lessonPlanContent, setLessonPlanContent] = useState('');
  const [isDataCollectionModalOpen, setIsDataCollectionModalOpen] = useState(false);
  const [isGeneratingDataCollection, setIsGeneratingDataCollection] = useState(false);
  const [dataCollectionContent, setDataCollectionContent] = useState('');

  const handleAIEdit = async (instruction) => {
    setActiveButton(instruction);
    try {
      const quillRef = getCurrentQuillRef();
      if (!quillRef || !quillRef.current) {
        console.error('No active editor found');
        return;
      }
      const editor = quillRef.current.getEditor();
      let text, format;
      
      if (storedSelection) {
        const range = { index: storedSelection.index, length: storedSelection.length };
        text = editor.getText(range.index, range.length);
        format = editor.getContents(range.index, range.length);
      } else {
        const currentSelection = editor.getSelection();
        if (currentSelection) {
          text = editor.getText(currentSelection.index, currentSelection.length);
          format = editor.getContents(currentSelection.index, currentSelection.length);
        } else {
          text = editor.getText();
          format = editor.getContents();
        }
      }

      const response = await fetchWithToken(`${API_BASE_URL}/api/edit-text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text,
          instruction,
          format: true // Add this flag to tell the backend to preserve formatting
        }),
      });

      const { editedText } = await response.json();
      
      // Convert the edited text to maintain formatting
      const formattedText = editedText
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Convert markdown bold to HTML
        .replace(/^# (.*$)/gm, '<h1><strong>$1</strong></h1>')  // Convert headers
        .replace(/^## (.*$)/gm, '<h2><strong>$1</strong></h2>')
        .replace(/^### (.*$)/gm, '<h3><strong>$1</strong></h3>')
        .replace(/^• (.*$)/gm, '<p>• $1</p>')  // Convert bullet points
        .replace(/\n/g, '<br/>');  // Convert newlines

      if (storedSelection) {
        editor.deleteText(storedSelection.index, storedSelection.length);
        editor.clipboard.dangerouslyPasteHTML(storedSelection.index, formattedText);
      } else {
        const currentSelection = editor.getSelection();
        if (currentSelection) {
          editor.deleteText(currentSelection.index, currentSelection.length);
          editor.clipboard.dangerouslyPasteHTML(currentSelection.index, formattedText);
        }
      }
      
      setStoredSelection(null);
      setShowAIPrompt(false);
    } catch (error) {
      console.error("Error during AI edit:", error);
    } finally {
      setActiveButton(null);
    }
  };

  // Modify the handleTextSelection function
  const handleTextSelection = () => {
    console.log('handleTextSelection called');
    
    const quillRef = getCurrentQuillRef();
    console.log('Current Quill Ref:', quillRef);
    
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor();
      console.log('Editor instance:', editor);
      
      const selection = editor.getSelection();
      console.log('Selection:', selection);
      
      if (selection && selection.length > 0) {
        console.log('Valid selection detected, length:', selection.length);
        setStoredSelection(selection);
        
        // Get the bounds of the selection
        const bounds = editor.getBounds(selection.index, selection.length);
        console.log('Selection bounds:', bounds);
        
        // Get the modal element and its position
        const modalElement = document.querySelector('.Modal');
        if (!modalElement) {
          console.error('Modal element not found');
          return;
        }
        
        const modalRect = modalElement.getBoundingClientRect();
        console.log('Modal rectangle:', modalRect);
        
        // Calculate popup position
        const popupWidth = 300;
        const left = modalRect.left + (modalRect.width - popupWidth) / 2;
        
        // Add scroll position to get absolute position
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const top = bounds.top + modalRect.top + bounds.height + scrollTop;
        
        console.log('Calculated popup position:', { top, left });
        
        setPromptPosition({ top, left });
        setShowAIPrompt(true);
        
        console.log('AI prompt popup should now be visible');
      } else {
        console.log('No valid selection detected');
      }
    } else {
      console.error('No active Quill editor reference found');
    }
  };

  // Modify the useEffect for selection changes
  useEffect(() => {
    console.log('Selection change useEffect triggered');
    console.log('Current modal states:', {
      isIEPModalOpen,
      isUDLModalOpen,
      isLessonPlanModalOpen,
      isDataCollectionModalOpen
    });
    
    const quillRef = getCurrentQuillRef();
    console.log('Current Quill ref in useEffect:', quillRef);
    
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor();
      console.log('Editor instance in useEffect:', editor);
      
      const handleSelectionChange = (range, oldRange, source) => {
        console.log('Selection changed:', {
          range,
          oldRange,
          source
        });
        
        if (range && range.length > 0) {
          console.log('Calling handleTextSelection due to selection change');
          handleTextSelection();
        } else {
          console.log('No selection or zero-length selection');
        }
      };

      editor.on('selection-change', handleSelectionChange);
      console.log('Selection change handler attached to editor');
      
      return () => {
        console.log('Cleaning up selection change handler');
        editor.off('selection-change', handleSelectionChange);
      };
    } else {
      console.log('No Quill editor available for selection handling');
    }
  }, [isIEPModalOpen, isUDLModalOpen, isLessonPlanModalOpen, isDataCollectionModalOpen]);

  // Add this helper function to check modal state
  const logModalState = () => {
    console.log('Current Modal States:', {
      IEP: isIEPModalOpen,
      UDL: isUDLModalOpen,
      LessonPlan: isLessonPlanModalOpen,
      DataCollection: isDataCollectionModalOpen
    });
    
    const activeEditor = getCurrentQuillRef();
    console.log('Active Editor:', activeEditor?.current ? 'Available' : 'Not Available');
  };

  // Add this useEffect to handle clicks outside popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowAIPrompt(false);
      }
    };

    if (showAIPrompt) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAIPrompt]);

  // Add this new function inside ChatInterface component
  const handleTextAreaInput = (e) => {
    const textarea = e.target;
    // Reset height before calculating scrollHeight
    textarea.style.height = '32px';
    // Set new height based on content
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    setInput(e.target.value);
  };

  // Add state for templates dropdown
  const [showTemplatesDropdown, setShowTemplatesDropdown] = useState(false);

  // Add this state near your other state declarations
  const [loadingTemplate, setLoadingTemplate] = useState('');

  // Modify the handleTemplateSelect function
  const handleTemplateSelect = async (templateType) => {
    setLoadingTemplate(templateType);
    try {
      switch(templateType) {
        case 'IEP':
          setIsGeneratingIEP(true);
          const iepContent = await generateIEP(fetchWithToken, API_BASE_URL, chatMessages, true);
          setIepContent(iepContent);
          setIsIEPModalOpen(true);
          setIsGeneratingIEP(false);
          break;
        case 'UDL':
          setIsGeneratingUDL(true);
          const udlContent = await generateUDL(fetchWithToken, API_BASE_URL, chatMessages, true);
          setUdlContent(udlContent);
          setIsUDLModalOpen(true);
          setIsGeneratingUDL(false);
          break;
        case 'LessonPlan':
          console.log('Starting Lesson Plan generation...');
          setIsGeneratingLessonPlan(true);
          const lessonPlanContent = await generateLessonPlan(fetchWithToken, API_BASE_URL, chatMessages, true);
          console.log('Lesson Plan content received:', lessonPlanContent);
          setLessonPlanContent(lessonPlanContent);
          console.log('Setting modal to open...');
          setIsLessonPlanModalOpen(true);
          setIsGeneratingLessonPlan(false);
          break;
        case 'DataCollection':
          setIsGeneratingDataCollection(true);
          const dataCollectionContent = await generateDataCollection(fetchWithToken, API_BASE_URL, chatMessages, true);
          setDataCollectionContent(dataCollectionContent);
          setIsDataCollectionModalOpen(true);
          setIsGeneratingDataCollection(false);
          break;
      }
    } catch (error) {
      console.error(`Error generating ${templateType}:`, error);
    } finally {
      setLoadingTemplate('');
      setShowTemplatesDropdown(false);
      // Reset all generating states in case of error
      setIsGeneratingIEP(false);
      setIsGeneratingUDL(false);
      setIsGeneratingLessonPlan(false);
      setIsGeneratingDataCollection(false);
    }
  };

  // Add this new useEffect to initialize the editor when the modal opens
  useEffect(() => {
    console.log('Modal state change detected');
    // Wait for next tick to ensure modal is rendered
    setTimeout(() => {
      const quillRef = getCurrentQuillRef();
      if (quillRef?.current) {
        console.log('Quill editor found, initializing selection handling');
        const editor = quillRef.current.getEditor();
        
        // Enable text selection
        editor.enable();
        
        // Add selection change handler
        const handleSelectionChange = (range, oldRange, source) => {
          console.log('Selection changed:', { range, oldRange, source });
          if (range && range.length > 0) {
            handleTextSelection();
          }
        };
        
        editor.on('selection-change', handleSelectionChange);
        
        return () => {
          console.log('Cleaning up editor event listeners');
          editor.off('selection-change', handleSelectionChange);
        };
      } else {
        console.log('No Quill editor found after modal open');
      }
    }, 100); // Small delay to ensure modal is rendered
  }, [isIEPModalOpen, isUDLModalOpen, isLessonPlanModalOpen, isDataCollectionModalOpen]);

  return (
    <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm h-full flex flex-col">
      <div className="flex flex-col h-full">
        {/* Document selection - only shown if documents exist */}
        {documents.length > 0 && (
          <div className="">
            <select
              value={selectedDocument || ""}
              onChange={(e) => handleDocumentChange(e.target.value)}
              className="w-full px-2 py-0 border rounded h-6"
            >
              <option value="">Select a document</option>
              {documents.map((doc) => (
                <option key={doc.id} value={doc.id}>
                  {doc.filename}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Button container - always visible */}
        <div className={`flex justify-between items-center ${documents.length > 0 ? 'mt-1' : 'mt-2 px-2'}`}>
          {/* Note section - only shown if documents exist */}
          {documents.length > 0 && (
            <span className="ml-2 flex items-center text-sm text-gray-600">
              Note:
              <span className="inline-block relative group ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-400 inline-block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 absolute left-0 bottom-full mb-1 w-64 text-center z-10">
                  While selected the document will be considered in responses.
                </span>
              </span>
            </span>
          )}

          {/* Quick Tips button moved to right */}
          <div className="ml-auto">
            <button 
              ref={quickTipButtonRef}
              className="btn-secondary px-2 py-1 text-sm"
              onClick={showRandomTip}
            >
              Quick Tips
            </button>
          </div>
        </div>

        {/* Tips popup */}
        {showTip && (
          <div
            className="fixed bg-white rounded-lg shadow-lg p-4 z-50 w-64 border border-black"
            style={{
              top: popupPosition.top,
              left: popupPosition.left,
              maxWidth: 'calc(100vw - 20px)',
            }}
          >
            {/* Close button */}
            <button
              onClick={() => setShowTip(false)}
              className="float-right text-gray-500 hover:text-gray-700 -mt-2 -mr-2"
              aria-label="Close"
            >
              ×
            </button>
            
            <div className="flex justify-center items-center mb-2 gap-3">
              <button 
                onClick={handlePreviousTip}
                className="px-2 text-gray-600 hover:text-gray-800"
              >
                &lt;
              </button>
              <span className="font-medium">Quick Tip</span>
              <button 
                onClick={handleNextTip}
                className="px-2 text-gray-600 hover:text-gray-800"
              >
                &gt;
              </button>
            </div>
            <p className="text-sm mb-2">{currentTip}</p>
            <button
              onClick={handleTellMeMore}
              className="text-blue-600 text-sm hover:underline"
            >
              Tell me more
            </button>
          </div>
        )}

        {/* Chat area */}
        <div 
          ref={chatContainerRef} 
          className="flex-1 overflow-y-auto pt-2 px-6 pb-6 space-y-6"
          onMouseEnter={() => setUserHasScrolled(true)}
          onMouseLeave={() => setUserHasScrolled(false)}
        >
          <Virtuoso
            ref={chatContainerRef}
            style={{ height: '100%', flex: 1 }}
            totalCount={chatMessages.filter(message => !message.hidden).length}
            followOutput={!userHasScrolled}
            alignToBottom={false}  // Change this to false
            initialTopMostItemIndex={
              isInitialLoad ? 0 : chatMessages.filter(m => !m.hidden).length - 1
            }
            itemContent={index => {
              const message = chatMessages.filter(m => !m.hidden)[index];
              return (
                <div
  id={`message-${index}`}
  className={`rounded-lg shadow-md relative border border-black mb-6 mx-6 ${
    message.role === "user"
      ? "bg-blue-100 ml-12 pt-2 pb-1 px-4"
      : message.role === "assistant"
        ? "bg-white mr-12 p-2 pb-6"
        : message.role === "system"
          ? "bg-yellow-100 text-center p-6"
          : "bg-red-100 p-6"
  }`}
                >
                  <div className="message-content">
                  <ReactMarkdown 
                    className="prose max-w-none"
                    remarkPlugins={[remarkGfm]}
                    components={{
                      h1: ({ node, ...props }) => (
                        <h1 
                          style={{
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            marginBottom: '0.75rem',  // Reduced from 1rem to 0.75rem
                            lineHeight: '2rem'
                          }} 
                          {...props} 
                        />
                      ),
                      h2: ({ node, ...props }) => (
                        <h2 
                          style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            marginBottom: '0.5rem',   // Reduced from 0.75rem to 0.5rem
                            lineHeight: '1.75rem'
                          }} 
                          {...props} 
                        />
                      ),
                      p: ({ node, ...props }) => (
                        <p 
                          style={{
                            marginBottom: '1rem',
                            lineHeight: '1.5'
                          }} 
                          {...props} 
                        />
                      ),
                      ul: ({ node, ...props }) => (
                        <ul 
                          style={{
                            listStyleType: 'disc',
                            paddingLeft: '1.5rem',
                            marginTop: '0.25rem',     // Added small top margin
                            marginBottom: '0.75rem'   // Reduced from 1rem to 0.75rem
                          }} 
                          {...props} 
                        />
                      ),
                      ol: ({ node, ...props }) => (
                        <ol 
                          style={{
                            listStyleType: 'decimal',
                            paddingLeft: '1.5rem',
                            marginTop: '0.25rem',     // Added small top margin
                            marginBottom: '0.75rem'   // Reduced from 1rem to 0.75rem
                          }} 
                          {...props} 
                        />
                      ),
                      li: ({ node, ...props }) => (
                        <li 
                          style={{
                            marginBottom: '0.5rem'
                          }} 
                          {...props} 
                        />
                      ),
                      table: ({ node, ...props }) => (
                        <div style={{ overflowX: 'auto', marginTop: '1rem', marginBottom: '1rem' }}>
                          <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                              minWidth: '100%',
                              border: '1px solid #e5e7eb'
                            }}
                            {...props}
                          />
                        </div>
                      ),
                      thead: ({ node, ...props }) => (
                        <thead
                          style={{
                            backgroundColor: '#f3f4f6'
                          }}
                          {...props}
                        />
                      ),
                      th: ({ node, ...props }) => (
                        <th
                          style={{
                            border: '1px solid #e5e7eb',
                            padding: '0.75rem 1rem',
                            textAlign: 'left',
                            fontWeight: '600'
                          }}
                          {...props}
                        />
                      ),
                      td: ({ node, ...props }) => (
                        <td
                          style={{
                            border: '1px solid #e5e7eb',
                            padding: '0.75rem 1rem',
                            whiteSpace: 'pre-line'
                          }}
                          {...props}
                        />
                      ),
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                  </div>
                  {message.role === "assistant" && (
                    <button
                      onClick={(e) => copyToClipboard(index, e)}  // Pass the event
                      className="absolute bottom-2 left-2 text-gray-500 hover:text-gray-700"
                      title="Copy to clipboard"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                        <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                      </svg>
                    </button>
                  )}
                </div>
              );
            }}
            components={{
              Footer: () => 
                isTyping ? (
                  <div className="typing-indicator mx-6 mb-6">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                ) : null
            }}
          />
        </div>
        {/* New code: Suggested Questions */}
        {/* Only render if there are suggested questions */}
        {suggestedQuestions.length > 0 && (
      <div className="mt-1 p-1 bg-gray-50 rounded-lg border border-gray-200">
        <SuggestedQuestions
          questions={suggestedQuestions}
          onQuestionClick={handleSuggestedQuestionClick}
        />
      </div>
    )}
        {/* Input form */}
        <form
  ref={formRef}
  onSubmit={handleSubmit}
  className="p-3 bg-white border-t border-gray-200"
>
  <div className="flex items-start space-x-4">
    <div className="flex-1"> {/* Add this wrapper div */}
      <AutocompleteInput
        value={input}
        onChange={(e, { newValue }) => {
          setInput(newValue);
          // Keep the auto-resize functionality
          if (inputRef.current) {
            inputRef.current.style.height = '32px';
            inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 200)}px`;
          }
        }}
        isLoading={isLoading}
        ref={inputRef}
      />
    </div>
    <button
      type="submit"
      className="btn-secondary h-[32px] min-h-[32px]"
      disabled={isLoading}
    >
      Send
    </button>
    <button
      type="button"
      onClick={clearChat}
      className="btn-secondary h-[32px] min-h-[32px] whitespace-nowrap" /* Add whitespace-nowrap */
    >
      Clear Chat
    </button>
    
    {/* Add Templates dropdown button */}
    <div className="relative">
      <button
        type="button"
        onClick={() => setShowTemplatesDropdown(!showTemplatesDropdown)}
        className="btn-secondary h-[32px] min-h-[32px]"
      >
        Templates
      </button>
      
      {/* Dropdown menu */}
      {showTemplatesDropdown && (
        <div className="absolute bottom-full mb-2 right-0 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50">
          <button
            onClick={() => handleTemplateSelect('IEP')}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
            disabled={loadingTemplate === 'IEP'}
          >
            <span>IEP Template</span>
            {loadingTemplate === 'IEP' && (
              <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
          </button>
          <button
            onClick={() => handleTemplateSelect('UDL')}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
            disabled={loadingTemplate === 'UDL'}
          >
            <span>UDL Template</span>
            {loadingTemplate === 'UDL' && (
              <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
          </button>
          <button
            onClick={() => handleTemplateSelect('LessonPlan')}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
            disabled={loadingTemplate === 'LessonPlan'}
          >
            <span>Lesson Plan Template</span>
            {loadingTemplate === 'LessonPlan' && (
              <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
          </button>
          <button
            onClick={() => handleTemplateSelect('DataCollection')}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
            disabled={loadingTemplate === 'DataCollection'}
          >
            <span>Data Collection Template</span>
            {loadingTemplate === 'DataCollection' && (
              <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
          </button>
        </div>
      )}
    </div>
  </div>
</form>

      {showGenerateIEP && (
        <div className="mt-4 p-3 bg-blue-100 rounded-lg">
          <p>It looks like you're discussing an IEP. Would you like to generate an IEP template?</p>
          <button
            onClick={handleGenerateIEP}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
            disabled={isGeneratingIEP}
          >
            {isGeneratingIEP ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Generating...
              </>
            ) : (
              'Generate IEP Template'
            )}
          </button>
        </div>
      )}

<Modal
        isOpen={isIEPModalOpen}
        onRequestClose={() => setIsIEPModalOpen(false)}
        contentLabel="IEP Editor"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {    
            width: '80%',
            maxWidth: '1200px',
            margin: 'auto',
            padding: '0',
          }
        }}
      >
        <div className="p-6 bg-white rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-semibold">IEP Template Editor</h3>
            <button
              onClick={() => {
                const content = iepQuillRef.current.getEditor().root.innerHTML;
                copyRichTextToClipboard(
                  content,
                  () => confirmAlert({
                    title: 'Success',
                    message: 'Content copied to clipboard with formatting!',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
                      }
                    ],
                    closeOnClickOutside: true,
                    closeOnEscape: true,
                    overlayClassName: "overlay-custom-class-name"
                  }),
                  (err) => confirmAlert({
                    title: 'Error',
                    message: 'Failed to copy content',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                      }
                    ]
                  })
                );
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </svg>
              Copy Content
            </button>
          </div>
          
          <div className="relative">
            <div className="quill-container" style={{ position: 'relative' }}>
              <ReactQuill
                ref={iepQuillRef}
                value={iepContent}
                onChange={setIepContent}
                modules={{
                  ...modules,
                  clipboard: {
                    matchVisual: false,
                  },
                  keyboard: {
                    bindings: {
                      // Add custom key bindings if needed
                    }
                  }
                }}
                formats={formats}
                className="min-h-[500px] w-full"
                onFocus={() => {
                  console.log('Editor focused');
                }}
                onBlur={() => {
                  console.log('Editor blurred');
                }}
                preserveWhitespace={true}
              />
            </div>
          </div>
          {/* Add this popup form */}
          {showAIPrompt && (
            <div 
              ref={popupRef}
              className="fixed bg-white shadow-lg rounded-lg p-4 border border-gray-200" 
              style={{ 
                position: 'absolute', 
                top: `${promptPosition.top}px`, 
                left: `${promptPosition.left}px`,
                width: '350px',
                transform: 'translate(-50%, 20px)',
                maxHeight: '250px',
                overflow: 'auto',
                zIndex: 9999,           // Make sure this is higher than modal
                pointerEvents: 'auto'   // Ensure clicks work
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="space-y-2 mb-4">
                <button
                  onClick={() => handleAIEdit('Simplify this text')}
                  disabled={activeButton === 'Simplify this text'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Simplify this text' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Simplify this text' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Simplifying...
                    </>
                  ) : (
                    'Simplify'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Make this more professional')}
                  disabled={activeButton === 'Make this more professional'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Make this more professional' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Make this more professional' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Making professional...
                    </>
                  ) : (
                    'Professional'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Elaborate on this')}
                  disabled={activeButton === 'Elaborate on this'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Elaborate on this' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Elaborate on this' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Elaborating...
                    </>
                  ) : (
                    'Elaborate'
                  )}
                </button>
              </div>

              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAIEdit(aiPrompt);
                  setAIPrompt('');
                  setShowAIPrompt(false);
                }}
                onClick={(e) => e.stopPropagation()}
                className="flex space-x-2" // Added flex and spacing
              >
                <input
                  type="text"
                  value={aiPrompt}
                  onChange={(e) => {
                    console.log('Custom field onChange event:', e.target.value);
                    e.stopPropagation();
                    setAIPrompt(e.target.value);
                  }}
                  onFocus={(e) => {
                    console.log('Custom field focused');
                    console.log('Current stored selection:', storedSelection);
                    e.stopPropagation();
                    setIsFocused(true);
                  }}
                  onBlur={(e) => {
                    console.log('Custom field blur event');
                    console.log('Related target:', e.relatedTarget);
                    e.stopPropagation();
                    if (!e.relatedTarget?.closest('input')) {
                      setIsFocused(false);
                      console.log('Focus state set to false');
                    }
                  }}
                  onClick={(e) => {
                    console.log('Custom field clicked');
                    e.stopPropagation();
                    e.target.focus();
                    e.preventDefault();
                  }}
                  placeholder="Custom instruction..."
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="px-3 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Send
                </button>
              </form>
            </div>
          )}

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={() => setIsIEPModalOpen(false)}
              className="px-6 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Close Editor
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isUDLModalOpen}
        onRequestClose={() => setIsUDLModalOpen(false)}
        contentLabel="UDL Editor"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {
            width: '80%',
            maxWidth: '1200px',
            margin: 'auto',
            padding: '0',
          }
        }}
      >
        <div className="p-6 bg-white rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-semibold">UDL Template Editor</h3>
            <button
              onClick={() => {
                const content = udlQuillRef.current.getEditor().root.innerHTML;
                copyRichTextToClipboard(
                  content,
                  () => confirmAlert({
                    title: 'Success',
                    message: 'Content copied to clipboard with formatting!',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
                      }
                    ],
                    closeOnClickOutside: true,
                    closeOnEscape: true,
                    overlayClassName: "overlay-custom-class-name"
                  }),
                  (err) => confirmAlert({
                    title: 'Error',
                    message: 'Failed to copy content',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                      }
                    ]
                  })
                );
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </svg>
              Copy Content
            </button>
          </div>
          
          <div className="relative">
            <div className="quill-container" style={{ position: 'relative' }}>
              <ReactQuill
                ref={udlQuillRef}
                value={udlContent}
                onChange={setUdlContent}
                modules={{
                  ...modules,
                  clipboard: {
                    matchVisual: false,
                  },
                  keyboard: {
                    bindings: {
                      // Add custom key bindings if needed
                    }
                  }
                }}
                formats={formats}
                className="min-h-[500px] w-full"
                onFocus={() => {
                  console.log('Editor focused');
                }}
                onBlur={() => {
                  console.log('Editor blurred');
                }}
                preserveWhitespace={true}
              />
            </div>
          </div>
          
          {showAIPrompt && (
            <div 
              ref={popupRef}
              className="fixed bg-white shadow-lg rounded-lg p-4 border border-gray-200" 
              style={{ 
                position: 'absolute', 
                top: `${promptPosition.top}px`, 
                left: `${promptPosition.left}px`,
                width: '350px',
                transform: 'translate(-50%, 20px)',
                maxHeight: '250px',
                overflow: 'auto',
                zIndex: 9999,           // Make sure this is higher than modal
                pointerEvents: 'auto'   // Ensure clicks work
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="space-y-2 mb-4">
                <button
                  onClick={() => handleAIEdit('Simplify this text')}
                  disabled={activeButton === 'Simplify this text'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Simplify this text' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Simplify this text' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Simplifying...
                    </>
                  ) : (
                    'Simplify'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Make this more professional')}
                  disabled={activeButton === 'Make this more professional'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Make this more professional' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Make this more professional' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Making professional...
                    </>
                  ) : (
                    'Professional'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Elaborate on this')}
                  disabled={activeButton === 'Elaborate on this'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Elaborate on this' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Elaborate on this' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Elaborating...
                    </>
                  ) : (
                    'Elaborate'
                  )}
                </button>
              </div>

              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAIEdit(aiPrompt);
                  setAIPrompt('');
                  setShowAIPrompt(false);
                }}
                onClick={(e) => e.stopPropagation()}
                className="flex space-x-2" // Added flex and spacing
              >
                <input
                  type="text"
                  value={aiPrompt}
                  onChange={(e) => {
                    console.log('Custom field onChange event:', e.target.value);
                    e.stopPropagation();
                    setAIPrompt(e.target.value);
                  }}
                  onFocus={(e) => {
                    console.log('Custom field focused');
                    console.log('Current stored selection:', storedSelection);
                    e.stopPropagation();
                    setIsFocused(true);
                  }}
                  onBlur={(e) => {
                    console.log('Custom field blur event');
                    console.log('Related target:', e.relatedTarget);
                    e.stopPropagation();
                    if (!e.relatedTarget?.closest('input')) {
                      setIsFocused(false);
                      console.log('Focus state set to false');
                    }
                  }}
                  onClick={(e) => {
                    console.log('Custom field clicked');
                    e.stopPropagation();
                    e.target.focus();
                    e.preventDefault();
                  }}
                  placeholder="Custom instruction..."
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="px-3 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Send
                </button>
              </form>
            </div>
          )}

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={() => setIsUDLModalOpen(false)}
              className="px-6 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Close Editor
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isLessonPlanModalOpen}
        onRequestClose={() => setIsLessonPlanModalOpen(false)}
        contentLabel="Lesson Plan Editor"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {
            width: '80%',
            maxWidth: '1200px',
            margin: 'auto',
            padding: '0',
          }
        }}
      >
        <div className="p-6 bg-white rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-semibold">Lesson Plan Template Editor</h3>
            <button
              onClick={() => {
                const content = lessonPlanQuillRef.current.getEditor().root.innerHTML;
                copyRichTextToClipboard(
                  content,
                  () => confirmAlert({
                    title: 'Success',
                    message: 'Content copied to clipboard with formatting!',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
                      }
                    ],
                    closeOnClickOutside: true,
                    closeOnEscape: true,
                    overlayClassName: "overlay-custom-class-name"
                  }),
                  (err) => confirmAlert({
                    title: 'Error',
                    message: 'Failed to copy content',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                      }
                    ]
                  })
                );
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </svg>
              Copy Content
            </button>
          </div>
          
          <div className="relative">
            <div className="quill-container" style={{ position: 'relative' }}>
              <ReactQuill
                ref={lessonPlanQuillRef}
                value={lessonPlanContent}
                onChange={setLessonPlanContent}
                modules={{
                  ...modules,
                  clipboard: {
                    matchVisual: false,
                  },
                  keyboard: {
                    bindings: {
                      // Add custom key bindings if needed
                    }
                  }
                }}
                formats={formats}
                className="min-h-[500px] w-full"
                onFocus={() => {
                  console.log('Editor focused');
                }}
                onBlur={() => {
                  console.log('Editor blurred');
                }}
                preserveWhitespace={true}
              />
            </div>
          </div>
          
          {showAIPrompt && (
            <div 
              ref={popupRef}
              className="fixed bg-white shadow-lg rounded-lg p-4 border border-gray-200" 
              style={{ 
                position: 'absolute', 
                top: `${promptPosition.top}px`, 
                left: `${promptPosition.left}px`,
                width: '350px',
                transform: 'translate(-50%, 20px)',
                maxHeight: '250px',
                overflow: 'auto',
                zIndex: 9999,           // Make sure this is higher than modal
                pointerEvents: 'auto'   // Ensure clicks work
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="space-y-2 mb-4">
                <button
                  onClick={() => handleAIEdit('Simplify this text')}
                  disabled={activeButton === 'Simplify this text'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Simplify this text' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Simplify this text' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Simplifying...
                    </>
                  ) : (
                    'Simplify'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Make this more professional')}
                  disabled={activeButton === 'Make this more professional'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Make this more professional' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Make this more professional' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Making more professional...
                    </>
                  ) : (
                    'Make Professional'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Elaborate on this')}
                  disabled={activeButton === 'Elaborate on this'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Elaborate on this' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Elaborate on this' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Elaborating...
                    </>
                  ) : (
                    'Elaborate'
                  )}
                </button>
              </div>

              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAIEdit(aiPrompt);
                  setAIPrompt('');
                  setShowAIPrompt(false);
                }}
                onClick={(e) => e.stopPropagation()}
                className="flex space-x-2" // Added flex and spacing
              >
                <input
                  type="text"
                  value={aiPrompt}
                  onChange={(e) => {
                    console.log('Custom field onChange event:', e.target.value);
                    e.stopPropagation();
                    setAIPrompt(e.target.value);
                  }}
                  onFocus={(e) => {
                    console.log('Custom field focused');
                    console.log('Current stored selection:', storedSelection);
                    e.stopPropagation();
                    setIsFocused(true);
                  }}
                  onBlur={(e) => {
                    console.log('Custom field blur event');
                    console.log('Related target:', e.relatedTarget);
                    e.stopPropagation();
                    if (!e.relatedTarget?.closest('input')) {
                      setIsFocused(false);
                      console.log('Focus state set to false');
                    }
                  }}
                  onClick={(e) => {
                    console.log('Custom field clicked');
                    e.stopPropagation();
                    e.target.focus();
                    e.preventDefault();
                  }}
                  placeholder="Custom instruction..."
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="px-3 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Send
                </button>
              </form>
            </div>
          )}

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={() => setIsLessonPlanModalOpen(false)}
              className="px-6 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Close Editor
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isDataCollectionModalOpen}
        onRequestClose={() => setIsDataCollectionModalOpen(false)}
        contentLabel="Data Collection Editor"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {
            width: '80%',
            maxWidth: '1200px',
            margin: 'auto',
            padding: '0',
          }
        }}
      >
        <div className="p-6 bg-white rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-semibold">Data Collection Template Editor</h3>
            <button
              onClick={() => {
                const content = dataCollectionQuillRef.current.getEditor().root.innerHTML;
                copyRichTextToClipboard(
                  content,
                  () => confirmAlert({
                    title: 'Success',
                    message: 'Content copied to clipboard with formatting!',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600'
                      }
                    ],
                    closeOnClickOutside: true,
                    closeOnEscape: true,
                    overlayClassName: "overlay-custom-class-name"
                  }),
                  (err) => confirmAlert({
                    title: 'Error',
                    message: 'Failed to copy content',
                    buttons: [
                      {
                        label: 'OK',
                        className: 'bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                      }
                    ]
                  })
                );
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </svg>
              Copy Content
            </button>
          </div>
          
          <div className="relative">
            <div className="quill-container" style={{ position: 'relative' }}>
              <ReactQuill
                ref={dataCollectionQuillRef}
                value={dataCollectionContent}
                onChange={setDataCollectionContent}
                modules={{
                  ...modules,
                  clipboard: {
                    matchVisual: false,
                  },
                  keyboard: {
                    bindings: {
                      // Add custom key bindings if needed
                    }
                  }
                }}
                formats={formats}
                className="min-h-[500px] w-full"
                onFocus={() => {
                  console.log('Editor focused');
                }}
                onBlur={() => {
                  console.log('Editor blurred');
                }}
                preserveWhitespace={true}
              />
            </div>
          </div>
          
          {showAIPrompt && (
            <div 
              ref={popupRef}
              className="fixed bg-white shadow-lg rounded-lg p-4 border border-gray-200" 
              style={{ 
                position: 'absolute', 
                top: `${promptPosition.top}px`, 
                left: `${promptPosition.left}px`,
                width: '350px',
                transform: 'translate(-50%, 20px)',
                maxHeight: '250px',
                overflow: 'auto',
                zIndex: 9999,           // Make sure this is higher than modal
                pointerEvents: 'auto'   // Ensure clicks work
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="space-y-2 mb-4">
                <button
                  onClick={() => handleAIEdit('Simplify this text')}
                  disabled={activeButton === 'Simplify this text'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Simplify this text' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Simplify this text' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Simplifying...
                    </>
                  ) : (
                    'Simplify'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Make this more professional')}
                  disabled={activeButton === 'Make this more professional'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Make this more professional' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Make this more professional' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Making professional...
                    </>
                  ) : (
                    'Professional'
                  )}
                </button>
                <button
                  onClick={() => handleAIEdit('Elaborate on this')}
                  disabled={activeButton === 'Elaborate on this'}
                  className={`w-full px-3 py-1 text-sm bg-blue-50 hover:bg-blue-100 rounded flex items-center justify-center ${
                    activeButton === 'Elaborate on this' ? 'opacity-75 cursor-wait' : ''
                  }`}
                >
                  {activeButton === 'Elaborate on this' ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Elaborating...
                    </>
                  ) : (
                    'Elaborate'
                  )}
                </button>
              </div>

              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAIEdit(aiPrompt);
                  setAIPrompt('');
                  setShowAIPrompt(false);
                }}
                onClick={(e) => e.stopPropagation()}
                className="flex space-x-2" // Added flex and spacing
              >
                <input
                  type="text"
                  value={aiPrompt}
                  onChange={(e) => {
                    console.log('Custom field onChange event:', e.target.value);
                    e.stopPropagation();
                    setAIPrompt(e.target.value);
                  }}
                  onFocus={(e) => {
                    console.log('Custom field focused');
                    console.log('Current stored selection:', storedSelection);
                    e.stopPropagation();
                    setIsFocused(true);
                  }}
                  onBlur={(e) => {
                    console.log('Custom field blur event');
                    console.log('Related target:', e.relatedTarget);
                    e.stopPropagation();
                    if (!e.relatedTarget?.closest('input')) {
                      setIsFocused(false);
                      console.log('Focus state set to false');
                    }
                  }}
                  onClick={(e) => {
                    console.log('Custom field clicked');
                    e.stopPropagation();
                    e.target.focus();
                    e.preventDefault();
                  }}
                  placeholder="Custom instruction..."
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="px-3 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Send
                </button>
              </form>
            </div>
          )}

          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={() => setIsDataCollectionModalOpen(false)}
              className="px-6 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Close Editor
            </button>
          </div>
        </div>
      </Modal>
      </div>
    </div>
  );
};
export default ChatInterface;
