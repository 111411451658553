import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import ChatInterface from './components/ChatInterface';
import Profile from './components/Profile';
import { useAuth0 } from '@auth0/auth0-react';
import Welcome from './components/Welcome';
import History from './components/History';
import { ChatProvider } from './ChatContext';
import Documents from './components/Documents';
import GetStarted from './components/GetStarted';
import Resources from './components/Resources';
import Terms from "./pages/Terms";
import { SubscriptionGate } from './components/SubscriptionCheck';

function App() {
  const { isAuthenticated, isLoading, error, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user && window.location.search.includes('code=')) {
      const isPaid = user?.['https://login.asterio.io/roles']?.includes('Paid');
      if (!isPaid) {
        window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_LINK;
      }
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <ChatProvider>
      <Router>
        {isAuthenticated ? (
          <Layout>
            <SubscriptionGate>
              <Routes>
                <Route path="/" element={<ChatInterface />} />
                <Route path="/get-started" element={<GetStarted />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/history" element={<History />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/terms" element={<Terms />} />
              </Routes>
            </SubscriptionGate>
          </Layout>
        ) : (
          <Welcome />
        )}
      </Router>
    </ChatProvider>
  );
}

export default App;
